import { AppBar, Box, Button, Typography, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { Progress } from "./Progress";
import Cookies from "js-cookie";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import AlertNotif from "components/AlertNotif";
import { toast } from "react-hot-toast";

interface HeaderProps {
  step: number;
  taskData: {
    [key: string]: any;
  };
}

const calculatePageTitle = (step: number) => {
  switch (step) {
    case 1:
      return "Title";
    case 2:
      return "Location";
    case 3:
    case 4:
      return "Date & Time";
    case 5:
      return "Budget";
    case 6:
      return "Details";
    // case 7:
    //   return "Attachments";
    case 7:
      return "Summary";
  }
};

export const Header = ({ step, taskData }: HeaderProps): JSX.Element => {
  const { palette } = useTheme();
  const { push } = useHistory();

  const handleDraft = () => {
    if (Cookies.get("copyJob")) {
      Cookies.remove("copyJob");
    }

    if (Cookies.get("postTask")) {
      Cookies.remove("postTask");
    }

    if (Cookies.get("editJob")) {
      Cookies.remove("editJob");
    }
    if (taskData["title"]) {
      Cookies.set("draft", JSON.stringify(taskData), { expires: 1 });
      toast.custom((t) => (
        <AlertNotif
          title={"Your task has been saved as a Draft"}
          type="primary"
          target={t}
        />
      ));
    }
    push("/inventory");
  };

  // useEffect(() => {
  //   if (Cookies.get("copyJob")) {
  //     Cookies.remove("copyJob");
  //   }

  //   if (Cookies.get("editJob")) {
  //     Cookies.remove("editJob");
  //   }
  //   if (taskData["title"]) {
  //     Cookies.set("draft", JSON.stringify(taskData), { expires: 1 });
  //   }
  // }, [taskData]);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      sx={{
        backgroundColor: palette.neutral.min,
        top: 0,
        display: "block",
        zIndex: 700,
        pt: { md: "26px", xs: "16px" },
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: { xs: "flex", md: "flex" } }}>
          <Link to="/inventory">
            <img
              src={"/images/taskpin-logo.svg"}
              style={{ cursor: "pointer", display: "flex" }}
              alt=""
            />
          </Link>
        </Box>
        {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Typography variant="subtitle1">
            {calculatePageTitle(step)}
          </Typography>
        </Box> */}
        <Box>
          {/* <Button
            sx={{
              backgroundColor: palette.neutral[900],
              color: palette.neutral.min,
              ":hover": { backgroundColor: palette.neutral[900] },
              borderRadius: "4px",
              mr: 2,
              maxWidth: "35px",
              maxHeight: "35px",
              minWidth: "35px",
              minHeight: "35px",
              ":focus": {
                outline: "none",
              },
            }}
          >
            <NightlightOutlinedIcon sx={{ width: "19px" }} />
          </Button> */}
          <Button
            onClick={handleDraft}
            sx={{
              borderRadius: "4px",
              maxWidth: "35px",
              maxHeight: "35px",
              minWidth: "35px",
              minHeight: "35px",
              color: palette.neutral[500],
              border: `1px solid ${palette.neutral[400]}`,
              ":focus": {
                outline: "none",
              },
            }}
          >
            <CloseRoundedIcon sx={{ width: "19px" }} />
          </Button>
        </Box>
      </Container>
      <Progress step={step} />
    </AppBar>
  );
};

import useAccountInfoStore from "store/zustand/accountInformation";

const CustomEvents = () => {
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );

  const informations = {
    environment: process.env.SEGMENT_ENV,
    UserID: AccountInfo.id,
    FirstName: AccountInfo.fname,
    LastName: AccountInfo.lname,
    Role: AccountInfo.role,
    Email: AccountInfo.email,
    Mobile: AccountInfo.mobile,
    Location: AccountInfo.location,
    TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    DeviceType: "Web",
    TaskAlertLabels: null,
  };

  return informations;
};

export default CustomEvents;

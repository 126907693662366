import { ChangeEvent, useCallback, useState, useEffect } from "react";

import {
  Box,
  Button,
  Divider,
  Fade,
  styled,
  TextField,
  Typography,
  useAutocomplete,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import HailRoundedIcon from "@mui/icons-material/HailRounded";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { Step } from "../Step";
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import {
  getFakeLocation,
  getLocations,
} from "../../../../api/postJob/location";
import { useQuery } from "react-query";
import { Icon } from "components/Icon";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { useHistory, useLocation } from "react-router-dom";
import { getCurrentLocations } from "api/postJob/currentLocation";
import AlertNotif from "components/AlertNotif";
import { toast } from "react-hot-toast";
import LoadingPages from "components/LoadingPages";
import Cookies from "js-cookie";

const ITEMS = [
  {
    name: "inPerson",
    label: "In-Person",
    icon: <HailRoundedIcon />,
  },
  {
    name: "remote",
    label: "Remote",
    icon: <LanguageRoundedIcon />,
  },
];

const InputWrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${theme.palette.neutral[100]};
  color: ${theme.palette.neutral[700]}
  background: #FFFFFF;
  border:none;
  border-radius: 4px 0px 0px 4px;
  flex: none;
  order: 0;
  flex-grow: 1;
  
  & input {
    box-sizing: border-box;
    height:100%;
    padding: 0px 18px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

const Listbox = styled("ul")(({ theme }) => ({
  margin: 0,
  padding: 0,
  position: "absolute",
  listStyle: "none",
  backgroundColor: theme.palette.background.paper,
  overflow: "auto",
  maxHeight: "260px",
  background: "#FFFFFF",
}));

interface LocationPropsType {
  reset: UseFormReset<FieldValues>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  handleStep?: any;
  setLocationData?: any;
  locationData?: any;
  taskData?: any;
  setTaskData?: any;
}

export const Location = ({
  setTaskData,
  taskData,
  register,
  control,
  reset,
  handleStep,
  locationData,
  setLocationData,
}: LocationPropsType): JSX.Element => {
  const [focusedInput, setFocusedInput] = useState(true);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const { palette, breakpoints } = useTheme();
  const smallSize = useMediaQuery(breakpoints.down("md"));
  const under360 = useMediaQuery("(max-width:360px)");
  const [searchText, setSearchText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { data, isLoading } = useQuery(["locations", searchText], () =>
    getLocations(searchText as string)
  );
  const history = useHistory();

  useEffect(() => {
    if (openAutocomplete) {
      document.getElementById("locations-autocomplete")?.focus();
    }
  }, [openAutocomplete]);

  useEffect(() => {
    if (Cookies.get("editJob")) {
      setTaskData((current: any) => ({
        ...current,
        fake_latitude: taskData.coordinates[1],
        fake_longitude: taskData.coordinates[0],
        location: taskData?.location,
      }));
      setSearchText(taskData.location);
    }
  }, [Cookies.get("editJob")]);

  const { getRootProps, getInputProps, getListboxProps, setAnchorEl } =
    useAutocomplete({
      id: "locations-autocomplete",
      options: data?.features
        ? data?.features
        : [
            {
              place_name: "",
            },
          ],
      getOptionLabel: (option: { place_name: string }) =>
        option.place_name || "",
      onChange: (event, value: any, reason, details) => {
        setTaskData((current: any) => ({
          ...current,
          location: value?.place_name,
          coordinates: value?.geometry.coordinates,
        }));
        reset({
          location: value?.text,
          coordinates: value?.geometry?.coordinates,
        });
        if (reason === "selectOption") setOpenAutocomplete(false);
      },
    });

  const handleFocus = () => {
    setFocusedInput(true);
    if (smallSize) {
      setOpenAutocomplete(true);
    }
  };

  const hanldeBlur = () => {
    setFocusedInput(false);
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);

      setTaskData((current: any) => ({
        ...current,
        location: e?.target?.value,
        coordinates: null,
      }));
    },
    [setTaskData, taskData]
  );

  useEffect(() => {
    if (locationData)
      getFakeLocation(locationData?.center).then((res) => {
        setTaskData((current: any) => ({
          ...current,
          fake_latitude:
            res.features[res.features.length - 1].geometry.coordinates[0],
          fake_longitude:
            res.features[res.features.length - 1].geometry.coordinates[1],
        }));
      });
  }, [locationData?.center]);

  // GET CURRENT LOCATION
  const getCurrentLocation = () => {
    const showPosition = (position: any) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
          getCurrentLocations(
            `${pos.coords.longitude},${pos.coords.latitude}`
          ).then((res) => {
            if (!res.features[0]) {
              setLoading(false);
              toast.custom((t) => (
                <AlertNotif
                  title={"Taskpin is not operating in this region"}
                  type="primary"
                  target={t}
                />
              ));
            } else {
              setLoading(false);
              setLocationData(res.features[0]);
              setTaskData((current: any) => ({
                ...current,
                location: res.features[0]?.place_name,
                coordinates: res.features[0]?.geometry.coordinates,
              }));
              setOpenAutocomplete(false);
            }
          });
        });
      }
    };

    const showError = (error: any) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          setLoading(false);
          toast.custom((t) => (
            <AlertNotif
              title={"You must turn on your location services"}
              type="primary"
              target={t}
            />
          ));
          break;
        case error.POSITION_UNAVAILABLE:
          setLoading(false);
          toast.custom((t) => (
            <AlertNotif
              title={"Location information is unavailable."}
              type="primary"
              target={t}
            />
          ));
          break;
        case error.TIMEOUT:
          setLoading(false);
          toast.custom((t) => (
            <AlertNotif
              title={"The request to get user location timed out."}
              type="primary"
              target={t}
            />
          ));
          break;
        case error.UNKNOWN_ERROR:
          setLoading(false);
          toast.custom((t) => (
            <AlertNotif
              title={"An unknown error occurred."}
              type="primary"
              target={t}
            />
          ));
          break;
      }
    };

    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      toast.custom((t) => (
        <AlertNotif
          title={"Geolocation is not supported by this browser."}
          type="primary"
          target={t}
        />
      ));
    }
  };

  return (
    <Step title="Location">
      <Box sx={{ width: { xs: "100%", sm: "558px" }, m: "0 auto" }}>
        <Typography variant="h5">Where do you need your task done?</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: under360 ? "block" : "flex",
            mt: { xs: 3, md: 2 },
            "& > *:first-child": { mr: 2 },
            width: { xs: "100%", sm: "558px" },
          }}
        >
          {ITEMS.map(({ name, label, icon }, index) => (
            <Box
              component="label"
              htmlFor={`locationType-${name}`}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                width: { xs: "100%", md: "271px" },
                py: under360 ? "12px" : { xs: "22px", md: "14px" },
                px: under360 ? "16px" : { xs: "24px", md: 3 },
                cursor: "pointer",
                border: {
                  md: `${
                    taskData.locationType === name
                      ? `1px solid ${palette.primary.main}`
                      : `1px solid ${palette.neutral[100]}`
                  }`,
                  xs: `${
                    taskData.locationType === name
                      ? `2px solid ${palette.primary.main}`
                      : `2px solid ${palette.neutral[100]}`
                  }`,
                },
                outline:
                  taskData.locationType === name ? "3px solid transparent" : "",
                outlineWidth: "2px",
                outlineColor: { xs: "none", lg: palette.primary.main },
                borderRadius: 2,
                input: { display: "none" },
              }}
            >
              <input
                {...register("locationType", {
                  required: true,
                  onChange: (e) => {
                    e.persist();

                    setTaskData((current: any) => ({
                      ...current,
                      locationType: e.target.value,
                    }));
                  },
                })}
                type="radio"
                name="locationType"
                value={name}
                id={`locationType-${name}`}
              />
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  mr: { xs: 0.5, md: 1 },
                  svg: { color: palette.primary.main },
                }}
              >
                {icon}
              </Box>
              <Typography variant={smallSize ? "button" : "h6"}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            transition: "all 0.8s 0.005s",
            width: { xs: "100%", sm: "558px", md: "558px" },
            mt: under360 ? "16px" : 3.5,
            position: "relative",
            flexDirection: { xs: "column", md: "row" },
            display: locationData ? "none" : "flex",
          }}
        >
          <Controller
            control={control}
            name="location"
            render={({ field: { ref, onChange, value, ...field } }) => (
              <Fade in={taskData.locationType === "inPerson"} timeout={500}>
                <TextField
                  {...field}
                  inputRef={ref}
                  sx={{
                    width: "100%",
                    "& fieldset": {
                      border: `1px solid ${palette.neutral[100]}`,
                    },
                  }}
                  onFocus={handleFocus}
                  onBlur={hanldeBlur}
                  onChange={handleChange}
                  value={taskData.location}
                  InputLabelProps={{
                    shrink: taskData.location ? true : undefined,
                  }}
                  InputProps={{
                    notched: taskData.location ? true : undefined,
                    endAdornment: (
                      <PinDropRoundedIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          mr: "5px",
                          color: focusedInput
                            ? palette.primary.main
                            : palette.neutral[500],
                        }}
                        onClick={() => {
                          setTaskData((current: any) => ({
                            ...current,
                            location: "",
                          }));
                        }}
                      />
                    ),
                  }}
                  inputProps={{
                    value: taskData.location,
                  }}
                  label="Your Address"
                />
              </Fade>
            )}
          />
          {data?.features?.length > 0 && taskData.location ? (
            <Box
              sx={{
                height: "150px",
                overflow: "overlay",
                boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.25)",
                position: "absolute",
                width: "100%",
                mt: "55px",
              }}
            >
              {data?.features?.map((item: any) => {
                return (
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      setLocationData(item);
                      setTaskData((current: any) => ({
                        ...current,
                        location: item?.place_name,
                        coordinates: item?.geometry.coordinates,
                      }));
                      reset({
                        location: item?.place_name,
                        coordinates: item?.geometry?.coordinates,
                      });
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      sx={{
                        width: "100%",
                        px: 2,
                        py: "14px",
                        m: 0,
                        ":hover": {
                          backgroundColor: palette.primary[50],
                        },
                      }}
                    >
                      <Box
                        display={"flex"}
                        ml={3}
                        flexDirection={"column"}
                        sx={{ width: "100%", p: 0, m: 0 }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            color: palette.neutral.max,
                            fontSize: "14px",
                          }}
                        >
                          {item.place_name}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          ) : null}
          <>
            <Box
              sx={{
                display: { xs: "none", md: "block" },
                position: "absolute",
                right: { lg: "-300px", md: "-150px" },
                top: "-93px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "48px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: palette.primary[50],
                  mt: -0.5,
                  mb: "6px",
                }}
              >
                <TipsAndUpdatesOutlinedIcon
                  sx={{
                    color: palette.primary.main,
                    width: "30px",
                    height: "30px",
                  }}
                />
              </Box>
              <Box sx={{ width: { lg: "227px", md: "120px" } }}>
                <Typography variant="caption">
                  Taskpin uses your provided address only to display the
                  approximate location of your task. Your precise location will
                  never be disclosed.
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
        {locationData && taskData.locationType === "inPerson" ? (
          <Box
            sx={{ width: { xs: "100%", sm: "558px", md: "558px" }, mt: "28px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="subtitle2">
                  {taskData.location?.substring(
                    0,
                    taskData.location?.indexOf(",")
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: palette.neutral[500] }}
                >
                  {taskData.location?.substring(
                    taskData.location?.indexOf(",") + 1
                  )}
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    "&:hover": { backgroundColor: palette.error.light },
                    height: { md: 35, xs: 40 },
                  }}
                  onClick={() => {
                    setTaskData((current: any) => ({
                      ...current,
                      location: "",
                      coordinates: null,
                    }));
                    setLocationData();
                    setSearchText("");
                  }}
                >
                  <Icon
                    iconSet="Delete"
                    sx={{
                      fontSize: "20px",
                      mr: "4px",
                      color: palette.error.main,
                    }}
                  />
                  <Typography
                    component="span"
                    variant="button"
                    sx={{
                      color: palette.error.main,
                    }}
                  >
                    Delete
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Button
              sx={{
                "&:hover": { backgroundColor: palette.primary[100] },
                bgcolor: palette.primary[50],
                mt: "24px",
                height: { md: 35, xs: 40 },
              }}
              onClick={() => history.push({ search: "Edit-address" })}
            >
              <Icon
                iconSet="Edit-Filled"
                sx={{ fontSize: "20px", mr: "4px" }}
              />
              <Typography
                component="span"
                variant="button"
                sx={{
                  color: palette.primary.main,
                }}
              >
                Edit Address
              </Typography>
            </Button>
          </Box>
        ) : null}
      </Box>
      {locationData && taskData.locationType === "inPerson" ? (
        <>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "center",
              alignItems: "center",
              py: 1,
              px: 2,
              width: "100%",
              borderRadius: "8px",
              backgroundColor: palette.neutral[50],
              mt: 2,
            }}
          >
            <TipsAndUpdatesOutlinedIcon
              sx={{
                color: palette.primary.main,
                width: "30px",
                height: "30px",
                mr: 1,
              }}
            />
            <Typography variant="caption">
              Taskpin uses your provided address only to display the approximate
              location of your task. Your precise location will never be
              disclosed.
            </Typography>
          </Box>
        </>
      ) : null}
      <Box
        sx={{
          display: openAutocomplete ? "flex" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 999,
          height: "calc(100vh - 80px)",
          width: "100%",
          backgroundColor: "white",
          mt: "-100px",
        }}
        onClick={(e) => e.preventDefault()}
      >
        {loading ? <LoadingPages /> : null}
        <Box
          sx={{ display: "flex", mt: "28px", width: "80%" }}
          {...getRootProps()}
        >
          <Icon
            iconSet="Arrow1-Left"
            sx={{
              mr: "8px",
              ml: "16px",
              color: palette.neutral[500],
              fontSize: "24px !important",
            }}
            handleClick={() => setOpenAutocomplete(false)}
          />
          <InputWrapper
            ref={setAnchorEl}
            sx={{
              position: "relative",
              width: "100%",
              height: "36px",
              ":focus": {
                outline: "none",
                border: "none",
              },
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchText(e.target.value);
            }}
          >
            <SearchRoundedIcon
              sx={{
                position: "absolute",
                ml: "12px",
                color: palette.neutral[500],
                mt: "-10px",
              }}
            />
            <input
              type="text"
              value={searchText}
              className="mobileLocations"
              placeholder="Enter your address"
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "18px",
                paddingLeft: "40px",
                width: "100% !important",
                height: "38px",
                marginTop: "-10px",
              }}
              {...getInputProps()}
            />
            {/* {(dirty || inputValue) && (
              <Box {...getClearProps()}>
                <CloseRoundedIcon
                  sx={{
                    position: "absolute",
                    right: "16px",
                    top: "12px",
                    width: "16px",
                    color: palette.neutral[700],
                    height: "16px",
                    ":hover": {
                      cursor: "pointer",
                    },
                  }}
                />
              </Box>
            )} */}
          </InputWrapper>
          <Box>
            <Listbox
              {...getListboxProps()}
              sx={{
                left: 0,
                top: 80,
                width: "100%",
                maxHeight: "calc(100vh - 80px)",
                pb: "80px",
              }}
            >
              {smallSize ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      px: "24px",
                      py: "12px",
                      ":hover": { backgroundColor: palette.primary[50] },
                      zIndex: 999,
                    }}
                    onClick={getCurrentLocation}
                  >
                    <Icon
                      iconSet="MyLocation-Filled"
                      sx={{ mr: "16px", color: palette.primary.main }}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: palette.neutral[700] }}
                    >
                      My Current Location
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ borderColor: palette.neutral[100], mx: "24px" }}
                  />
                </>
              ) : null}
              {data?.features.length > 0 && searchText
                ? data?.features.map((option: any, index: number) => {
                    if (index < 9) {
                      return (
                        <>
                          <Box
                            sx={{
                              px: "24px",
                              py: "12px",
                              ":hover": {
                                backgroundColor: palette.primary[50],
                              },
                            }}
                            key={index}
                            onClick={() => {
                              setLocationData(option);
                              setTaskData((current: any) => ({
                                ...current,
                                location: option?.place_name,
                                coordinates: option?.geometry?.coordinates,
                              }));
                              reset({
                                location: option?.place_name,
                                coordinates: option?.geometry?.coordinates,
                              });
                              setOpenAutocomplete(false);
                            }}
                          >
                            <Typography
                              variant="body2"
                              sx={{ color: palette.neutral.max }}
                            >
                              {option.place_name}
                            </Typography>
                          </Box>
                          {index + 1 === data?.features?.length ? null : (
                            <Divider
                              sx={{
                                borderColor: palette.neutral[100],
                                mx: "24px",
                              }}
                            />
                          )}
                        </>
                      );
                    }
                  })
                : null}
            </Listbox>
          </Box>
        </Box>
      </Box>
    </Step>
  );
};

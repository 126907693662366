import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useTheme,
  Popover,
} from "@mui/material";
import useAccountInfoStore from "store/zustand/accountInformation";
import { Icon } from "components/Icon";
import { useHistory } from "react-router-dom";

export const GetVerifiedModal = ({ id, open, anchorEl, handleClose }: any) => {
  const { palette } = useTheme();

  const { push } = useHistory();

  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );

  const setOpenVerified: any = useAccountInfoStore(
    (state) => state.setOpenVerified
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiPopover-paper": {
          borderRadius: "16px",
          boxShadow: "0px 2px 4px 0px #00000040",
          mt: { md: -10, xs: 4 },
          width: { xs: "100%", md: "auto" },
          display: { md: "unset", xs: "none" },
        },
      }}
    >
      <Box
        sx={{
          width: "360px",
          //   position: "absolute",
          bottom: 91,
          right: 20,
          boxShadow: "0px 0px 8px 0px #00000040",
          borderRadius: "16px",
          bgcolor: palette.neutral.min,
          py: 2,
          px: 3,
          zIndex: 999,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">
              {Math.round(AccountInfo?.account_status?.percentage.toFixed(2))}%
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: palette.neutral[600], ml: 1 }}
            >
              Completed
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            sx={{ color: palette.neutral[900], mt: 1 }}
          >
            Complete your profile to get Verified Badge
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: palette.neutral[600], mt: 0.5 }}
          >
            How Verified Badge help me?
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.basic_info_completed
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "account" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Name & About
            </Typography>
            {AccountInfo?.account_status?.basic_info_completed &&
            AccountInfo.about ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.mobile
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "account" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Mobile Number
            </Typography>
            {AccountInfo?.account_status?.mobile ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.biling_address
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "payment-settings" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Billing Address
            </Typography>
            {AccountInfo?.account_status?.biling_address ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.bank_account
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "payment-settings" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Bank Account
            </Typography>
            {AccountInfo?.account_status?.bank_account ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.portfolio
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "services" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Services
            </Typography>
            {AccountInfo?.account_status?.portfolio ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.skills
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/profile-tasker", search: "skills" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Skills
            </Typography>
            {AccountInfo?.account_status?.skills ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              "&:hover": {
                color: !AccountInfo?.account_status?.skills
                  ? palette.primary.main
                  : "",
                cursor: "pointer",
              },
            }}
            onClick={() => {
              push({ pathname: "/identity-check" });
              setOpenVerified("");
            }}
          >
            <Typography component="span" variant="subtitle2">
              Verify your Identity
            </Typography>
            {AccountInfo?.account_status?.skills ? (
              <Icon
                iconSet="CircleCheck-Filled"
                sx={{ color: palette.success.main }}
              />
            ) : (
              <Icon
                iconSet="ArrowCircle-Right"
                sx={{
                  color: palette.primary.main,
                  "&:hover": {
                    cursor: "Pointer",
                    fontVariationSettings: `"FILL" ${1}`,
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

import PageNotFound from "pages/errors/404";
import { PostJob } from "pages/postJob/PostJob";
// import EditJob from "pages/PostJob/edit";
// import CopyJob from "pages/PostJob/copy";
import React from "react";
// import ExploreJob from "pages/exploreJob";
// import Workspace from "pages/Workspace";
// import Inventory from "pages/Inventory";
// import Messaging from "pages/Messaging";
import Disconnect from "pages/errors/Disconnect";

const Home = React.lazy(() => import("./pages/Home"));
const Invite = React.lazy(() => import("./pages/Invite"));
const Jobs = React.lazy(() => import("./pages/Jobs"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Profile = React.lazy(() => import("./pages/ProfileVthree"));
const ProfilePublic = React.lazy(() =>
  import("./pages/Profile/components/Pages/ThirdPerson/PublicMenu")
);
const Inventory = React.lazy(() => import("./pages/Inventory"));
const Activity = React.lazy(() => import("./pages/Activity"));
const Workspace = React.lazy(() => import("./pages/Workspace"));
const exploreJob = React.lazy(() => import("./pages/exploreJob"));
const Messaging = React.lazy(() => import("./pages/Messaging"));
const Reviews = React.lazy(() => import("./pages/Reviews"));
const Payment = React.lazy(() => import("./pages/Payment"));
const IdentityCheck = React.lazy(() => import("./pages/IdentityCheck"));
const UnderMaintenance = React.lazy(() =>
  import("./components/UnderMaintenance")
);

const ChatView = React.lazy(() => import("./pages/ChatView"));
const Notifications = React.lazy(() => import("./pages/Notifications"));

//const Typography = React.lazy(() => import('./pages/TypographyTest'));

const router = [
  {
    path: "/home",
    exact: true,
    component: Home,
  },
  {
    path: "/Invite",
    exact: true,
    component: Invite,
  },
  {
    path: "/activity",
    exact: true,
    component: Activity,
  },
  {
    path: "/identity-check",
    exact: true,
    component: IdentityCheck,
  },
  {
    path: "/post-task",
    exact: true,
    component: PostJob,
  },
  // {
  //   path: "/post-job/:id",
  //   exact: true,
  //   component: (props) => <CreateJob {...props} mode="explore-jobs" />,
  // },
  {
    path: "/explore-tasks/:slug?",
    exact: true,
    component: exploreJob,
  },
  {
    path: "/workspace/:slug?",
    exact: true,
    component: Workspace,
  },
  {
    path: "/UnderMaintenance",
    exact: true,
    component: UnderMaintenance,
  },
  {
    path: "/inventory/:slug?",
    exact: true,
    component: Inventory,
  },
  {
    path: "/payments",
    exact: true,
    component: (props) => <Profile paymentRoute={true} {...props} />,
  },
  {
    path: "/notifications",
    exact: true,
    component: (props) => <Profile notificationRoute={true} {...props} />,
  },
  {
    path: "/my-jobs/:slug?",
    exact: true,
    component: (props) => <Jobs {...props} mode="my-jobs" />,
  },
  // {
  //   path: "/my-jobs/copy/:slug",
  //   exact: true,
  //   component: (props) => <CopyJob {...props} mode="explore-jobs" />,
  // },
  // {
  //   path: "/my-jobs/edit/:slug",
  //   exact: true,
  //   component: (props) => <EditJob {...props} mode="explore-jobs" />,
  // },
  {
    path: "/saved-jobs/:slug?",
    exact: true,
    component: (props) => <Jobs {...props} mode="saved-jobs" />,
  },
  {
    path: "/notifications",
    exact: true,
    component: Notifications,
  },
  {
    path: "/chats/:conversation_id?",
    exact: false,
    component: ChatView,
  },
  {
    path: "/messaging",
    exact: false,
    component: Messaging,
  },
  {
    path: "/profile-tasker",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile-poster",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile-poster",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile-public",
    exact: true,
    component: Profile,
  },
  {
    path: "/profile/:slug",
    exact: true,
    component: (props) => <Profile publicProfile={true} {...props} />,
  },
  {
    path: "/reviews",
    exact: true,
    component: Reviews,
  },
  {
    path: "/reviews/:slug",
    exact: true,
    component: Reviews,
  },
  {
    path: "/setting/:area?",
    exact: false,
    component: Settings,
  },
  {
    path: "/payment/:page?",
    exact: false,
    component: Payment,
  },
  {
    path: "/404",
    exact: false,
    component: PageNotFound,
  },
  {
    path: "/disconnect",
    exact: false,
    component: Disconnect,
  },
  /*{
        path: '/typography',
        exact: false,
        component: Typography
    }*/
];

export default router;

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Icon } from "components/Icon";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Badge } from "../Badge";
import LayoutContext from "../Context";
import { fetchUnreadNotificationsCount } from "api/exploreJob";
import WhatsNew from "components/WhatsNew";
import useAccountInfoStore from "store/zustand/accountInformation";
import useNotificationsCountStore from "store/zustand/notificationsCount";

interface MainNavMobileType {
  mode: string;
  isOther: boolean;
  account: any;
}
interface NavItemType {
  title: string;
  icon: ReactNode;
  activeIcon: ReactNode;
  url: string;
  badge?: ReactNode;
}

export const MainNavMobile = ({
  mode,
  isOther,
  account,
}: MainNavMobileType): JSX.Element => {
  const { palette } = useTheme();
  const history = useHistory();
  const { MessageBadgeCount } = useContext(LayoutContext);
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );
  const NotificationUnreade: any = useNotificationsCountStore(
    (state) => state.NotificationUnread
  );
  const setNotificationCount: any = useNotificationsCountStore(
    (state) => state.setNotificationUnread
  );
  const matches = useMediaQuery("(max-width:350px)");

  useEffect(() => {
    fetchUnreadNotificationsCount().then((res: any) =>
      setNotificationCount("unread_count", res.data.unread_count)
    );
  }, []);

  const tickerItems: Array<NavItemType> = [
    {
      title: "Explore",
      icon: <Icon iconSet="Search" />,
      activeIcon: <Icon iconSet="Search" />,
      url: "/explore-tasks",
    },
    {
      title: "Workspace",
      icon: <Icon iconSet="Work" />,
      activeIcon: <Icon iconSet="Work-Filled" />,
      url: "/workspace",
      // badge: (
      //   <Badge>
      //     <Typography
      //       component="span"
      //       sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
      //     >
      //       9
      //     </Typography>
      //   </Badge>
      // ),
    },
    {
      title: "Messaging",
      icon: <Icon iconSet="Chat" />,
      activeIcon: <Icon iconSet="Chat-Filled" />,
      url: "/messaging",
      badge: (
        <Badge>
          <Typography
            component="span"
            sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
          >
            1
          </Typography>
        </Badge>
      ),
    },
    {
      title: "Activity",
      icon: <Icon iconSet="DefaultNotif" />,
      activeIcon: <Icon iconSet="DefaultNotif-Filled" />,
      url: `/activity`,
      badge: (
        <Badge>
          <Typography
            component="span"
            sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
          >
            {NotificationUnreade?.unread_count &&
            NotificationUnreade?.unread_count > 0
              ? NotificationUnreade?.unread_count
              : null}
          </Typography>
        </Badge>
      ),
    },
    {
      title: "Profile",
      icon: <Icon iconSet="User" />,
      activeIcon: <Icon iconSet="User-Filled" />,
      url: "/profile-tasker",
      badge: (
        <Badge>
          <Icon iconSet="Check" />
        </Badge>
      ),
    },
  ];
  const posterItems: Array<NavItemType> = [
    {
      title: "Inventory",
      icon: <Icon iconSet="Inventory" />,
      activeIcon: <Icon iconSet="Inventory-Filled" />,
      url: "/inventory",
    },
    {
      title: "Messaging",
      icon: <Icon iconSet="Chat" />,
      activeIcon: <Icon iconSet="Chat-Filled" />,
      url: "/messaging",
      badge: (
        <Badge>
          <Typography
            component="span"
            sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
          >
            0
          </Typography>
        </Badge>
      ),
    },
    {
      title: "Activity",
      icon: <Icon iconSet="DefaultNotif" />,
      activeIcon: <Icon iconSet="DefaultNotif-Filled" />,
      url: `/activity`,
      badge: (
        <Badge>
          <Typography
            component="span"
            sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
          >
            {NotificationUnreade?.unread_count &&
            NotificationUnreade?.unread_count > 0
              ? NotificationUnreade?.unread_count
              : null}
          </Typography>
        </Badge>
      ),
    },
    {
      title: "Profile",
      icon: <Icon iconSet="User" />,
      activeIcon: <Icon iconSet="User-Filled" />,
      url: "/profile-poster",
      badge: (
        <Badge>
          <Icon iconSet="Check" />
        </Badge>
      ),
    },
  ];

  tickerItems[2].badge = (
    <Badge>
      <Typography
        component="span"
        sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
      >
        {MessageBadgeCount}
      </Typography>
    </Badge>
  );
  posterItems[1].badge = (
    <Badge>
      <Typography
        component="span"
        sx={{ fontSize: "10px !important", lineHeight: "16px !important" }}
      >
        {MessageBadgeCount}
      </Typography>
    </Badge>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        flexGrow: 1,
        px: "16px",
      }}
    >
      {mode === "ticker" &&
      AccountInfo &&
      (AccountInfo?.skills?.skills?.length < 1 || !AccountInfo.position) &&
      history.location.pathname.indexOf("profile") < 0 ? (
        <WhatsNew />
      ) : null}
      {mode === "ticker" &&
        tickerItems.map(({ title, icon, activeIcon, url, badge }, index) => (
          <Link key={index} to={url}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color:
                  history.location.pathname?.startsWith(url) && !isOther
                    ? palette.primary.main
                    : palette.neutral[500],
                position: "relative",
              }}
            >
              {history.location.pathname?.startsWith(url) &&
              !isOther &&
              activeIcon
                ? activeIcon
                : icon}
              {badge && (
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    color: palette.neutral.min,
                    alignItems: "center",
                    minWidth: "16px !important",
                    ...(title !== "Profile"
                      ? {
                          backgroundColor: palette.error.main,
                          px: "5.1px",
                          maxHeight: 16,
                          borderRadius: "60px",
                          top: "-4px",
                          left: title === "Messaging" ? "35px" : "24px",
                        }
                      : {
                          backgroundColor: palette.success.main,
                          height: 10,
                          minWidth: 10,
                          top: 0,
                          borderRadius: "100%",
                          right: "3px",
                          span: { fontSize: "9px", mt: "2px" },
                        }),
                  }}
                >
                  {badge}
                </Box>
              )}
              <Typography
                component="span"
                variant="label"
                sx={{ mt: 0.5, display: matches ? "none" : "" }}
              >
                {title}
              </Typography>
            </Box>
          </Link>
        ))}
      {mode === "poster" &&
        posterItems.map(({ title, activeIcon, icon, url, badge }, index) => (
          <Link key={index} to={url}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color:
                  history.location.pathname?.startsWith(url) && !isOther
                    ? palette.primary.main
                    : palette.neutral[500],
                position: "relative",
              }}
            >
              {history.location.pathname?.startsWith(url) &&
              !isOther &&
              activeIcon
                ? activeIcon
                : icon}
              {badge && (
                <Box
                  sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    color: palette.neutral.min,
                    alignItems: "center",
                    minWidth: "16px !important",
                    ...(title !== "Profile"
                      ? {
                          backgroundColor: palette.error.main,
                          px: 0.5,
                          maxHeight: 16,
                          borderRadius: "60px",
                          top: "-4px",
                          left: title === "Messaging" ? "35px" : "24px",
                        }
                      : {
                          backgroundColor: palette.success.main,
                          height: 10,
                          minWidth: 10,
                          top: 0,
                          borderRadius: "100%",
                          right: "3px",
                          span: { fontSize: "9px", mt: "2px" },
                        }),
                  }}
                >
                  {badge}
                </Box>
              )}
              <Typography
                component="span"
                variant="label"
                sx={{ mt: 0.5, display: matches ? "none" : "" }}
              >
                {title}
              </Typography>
            </Box>
          </Link>
        ))}
    </Box>
  );
};

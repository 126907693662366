import { Box, Modal, Slide, Typography, useTheme } from "@mui/material";
import { CustomButton } from "components/CustomButton";
import { Icon } from "components/Icon";
import { useState } from "react";

import useAccountInfoStore from "store/zustand/accountInformation";

const FirstPage = ({ setPages }: { setPages: any }): JSX.Element => {
  const { palette } = useTheme();
  const AccountInfo: any = useAccountInfoStore(
    (state) => state.AccountInformation
  );
  const [open, setOpen] = useState(true);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box
          sx={{
            // position: "absolute" as "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: { md: "16px", xs: "0" },
            py: 4,
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            outline: "none !important",
            width: {
              md: "917px",
              xs: "100%",
            },
            height: { md: "623px", xs: "100%" },
          }}
        >
          <Icon
            iconSet="Close"
            sx={{
              position: "absolute",
              left: 16,
              top: 16,
              "&:hover": { cursor: "pointer" },
            }}
            handleClick={() => {
              setOpen(false);
            }}
          />

          <Box
            sx={{
              width: "327px",
              textAlign: "center",
            }}
          >
            <Typography variant="body1" color={palette.neutral[700]}>
              Welcome to
            </Typography>
            <Typography variant="h2" sx={{ mt: 1 }}>
              Tasker Profile
            </Typography>
            <Typography
              variant="body1"
              color={palette.neutral[700]}
              sx={{ mt: 1 }}
            >
              To get started as a Tasker, we need some information about you.
            </Typography>
          </Box>
          <Box sx={{ width: "365px", mt: "64px" }}>
            {AccountInfo.skills?.skills?.length < 1 ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Icon
                  iconSet="Handyman"
                  sx={{ fontSize: "48px", color: palette.primary.main, mr: 2 }}
                />
                <Box>
                  <Typography variant="subtitle1">Skills</Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: palette.neutral[500] }}
                  >
                    Choose the skills that best describe you
                  </Typography>
                </Box>
              </Box>
            ) : null}
            {!AccountInfo?.position ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: AccountInfo.skills?.skills?.length < 1 ? 4 : 0,
                }}
              >
                <Icon
                  iconSet="Location"
                  sx={{ fontSize: "48px", color: palette.primary.main, mr: 2 }}
                />
                <Box>
                  <Typography variant="subtitle1">Location</Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: palette.neutral[500] }}
                  >
                    Tell us where you’re located
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              width: { md: "208px", xs: "100%" },
              bottom: { md: 32, xs: 0 },
              position: "absolute",
              px: "16px",
              bgcolor: palette.neutral.min,
              zIndex: 100,
              py: { xs: 2, md: 0 },
            }}
          >
            {" "}
            <CustomButton
              type="primary"
              text="Continue"
              sx={{ width: "100%" }}
              onClick={() => {
                if (AccountInfo.skills?.skills?.length < 1) {
                  setPages("Skills");
                } else setPages("Location");
              }}
            />
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default FirstPage;
